import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=09776014&scoped=true"
import script from "./Team.vue?vue&type=script&lang=js"
export * from "./Team.vue?vue&type=script&lang=js"
import style0 from "./Team.vue?vue&type=style&index=0&id=09776014&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09776014",
  null
  
)

/* custom blocks */
import block0 from "@/locales/team.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fclinic%2Fteam%2FTeam.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/team-header-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fclinic%2Fteam%2FTeam.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/articles-card-content.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fclinic%2Fteam%2FTeam.vue&external"
if (typeof block2 === 'function') block2(component)

export default component.exports