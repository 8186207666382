<i18n src="@/locales/team.json" />
<i18n src="@/locales/team-header-content.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="team">
    <div class="header-background">
      <header class="is-hidden-mobile">
        <h1 class="no-background is-size-5-mobile">
          <span>{{ $t('h1_title') }}</span>
        </h1>
        <div class="p-6">
          <b-carousel-list
            :items-to-show="5"
            :items-to-list="1"
            :repeat="true"
            :data="teamHeaderContent"
            icon-size="is-large"
            :arrow="true"
            :arrow-hover="false"
          >
            <template
              slot="item"
              slot-scope="list"
            >
              <figure class="image">
                <img :src="require('../../../assets/img/' + list.image)">
              </figure>
              <div class="item__description">
                <p class="item__title">
                  <strong>{{ list.title }}</strong>
                </p>
                <p class="item__subtitle">
                  {{ list.subtitle }}
                </p>
                <p class="item__location">
                  {{ list.location }}
                </p>
              </div>
            </template>
          </b-carousel-list>
        </div>
      </header>
      <header class="is-hidden-tablet">
        <h1 class="no-background is-size-5-mobile">
          <span>{{ $t('h1_title') }}</span>
        </h1>
        <div class="p-2">
          <b-carousel-list
            v-model="values"
            :items-to-show="1"
            :items-to-list="1"
            :repeat="true"
            :data="teamHeaderContent"
            icon-size="is-large"
            :arrow="true"
            :arrow-hover="false"
          >
            <template
              slot="item"
              slot-scope="list"
            >
              <figure class="image">
                <img :src="require('../../../assets/img/' + list.image)">
              </figure>
              <div class="item__description">
                <p class="item__title">
                  <strong>{{ list.title }}</strong>
                </p>
                <p class="item__subtitle">
                  {{ list.subtitle }}
                </p>
                <p class="item__location">
                  {{ list.location }}
                </p>
              </div>
            </template>
          </b-carousel-list>
        </div>
      </header>
    </div>
    <b-message>
      <p>
        {{ $t('message_1') }}
      </p>
    </b-message>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section columns is-centered">
      <div class="column is-10">
        <ImanTeamCard :content="teamHeaderContent[2]">
          <p>
            {{ teamHeaderContent[2].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[0]">
          <p>
            {{ teamHeaderContent[0].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[8]">
          <p>
            {{ teamHeaderContent[8].description }}
          </p>
        </ImanTeamCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section columns is-centered">
      <div class="column is-10">
        <ImanTeamCard :content="teamHeaderContent[4]">
          <p>
            {{ teamHeaderContent[4].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[3]">
          <p>
            {{ teamHeaderContent[3].description }}
          </p>
        </ImanTeamCard>
      </div>
    </section>
    <ImanH2>Autres services</ImanH2>
    <section class="section columns is-centered">
      <div class="column is-10">
        <ImanTeamCard :content="teamHeaderContent[10]">
          <p>
            {{ teamHeaderContent[10].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[5]">
          <p>
            {{ teamHeaderContent[5].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[6]">
          <p>
            {{ teamHeaderContent[6].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[7]">
          <p>
            {{ teamHeaderContent[7].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[11]">
          <p>
            {{ teamHeaderContent[11].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[12]">
          <p>
            {{ teamHeaderContent[12].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[1]">
          <p>
            {{ teamHeaderContent[1].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[13]">
          <p>
            {{ teamHeaderContent[13].description }}
          </p>
        </ImanTeamCard>
        <ImanTeamCard :content="teamHeaderContent[14]">
          <p>
            {{ teamHeaderContent[14].description }}
          </p>
        </ImanTeamCard>
      </div>
    </section>
    <section class="section">
      <ImanArticle>
        <b-message
          type="is-info"
        >
          <p>
            {{ $t('message_1') }}
          </p>
        </b-message>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection
      :content-list="filteredArticlesCardContent"
    />
  </div>
</template>

<script>
import ImanArticleSection from "@/components/ImanArticleSection";
import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
import ImanTeamCard from "@/components/ImanTeamCard";
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: 'Team',
  components: {
    ImanArticleSection,
    ImanTeamCard
  },
  mixins: [
    metaMixin,
    articlesCardContentFilterMixin
  ],
  data() {
    return {
      values: 2,
      articlesCardContentId: [83, 22, 40, 68],
      mainBackgroundClass: 'none-background',
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_4.7.png')
    },
    teamHeaderContent () {
      switch (this.$i18n.locale) {
        case 'en':
          return Object.values(this.$i18n.messages.en.teamHeaderContent)
        .filter(teamHeaderContent => teamHeaderContent)
        case 'ru':
          return Object.values(this.$i18n.messages.ru.teamHeaderContent)
            .filter(teamHeaderContent => teamHeaderContent)
        default:
          return Object.values(this.$i18n.messages.fr.teamHeaderContent)
            .filter(teamHeaderContent => teamHeaderContent)
      }
    }
  },
  }
</script>

<style lang="scss" scoped>
.header-background {
  padding-top: 2em;
  max-height: 1000px;
  @media only screen and (min-width: 768px) {
    /* tablet et ordinateurs */
    background-image: url("../../../assets/img/home/h1-home-d.jpg");
  }
  @media only screen and (max-width: 767px) {
    /* smartphone en mode paysage */
    background-image: url("../../../assets/img/home/h1-home-d.jpg");
  }
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    header {
      min-height: 600px;
    }
    .item__description {
      visibility: visible;
      text-align: center;
      align-items: center;
      margin-bottom: 1em;
      font-family: 'Comfortaa', cursive;
      font-size: 1em;
      font-weight: 400;
    }
    .item__title {
      font-size: 1.2em;
    }
    .carousel-slide img {
      transform: scale(1);
    }
    .image {
      margin: 0;
    }
    /* smartphone en mode portait */
    background-image: url("../../../assets/img/home/h1-home-m.jpg");
  }
}

.image {
  padding: 4em;
  margin: 2em 0;
  @media only screen and (max-width: 1540px) {
    padding: 2em;
  }
}

.carousel-list {
  background-color: rgba(255, 255, 255, 0.70);
}

.item__description {
  visibility: hidden;
}

.carousel-slide {
  display: flex;
  flex-direction: column;
}

.carousel-slide img {
  filter: grayscale(1);
}

.carousel-slide.is-active + .carousel-slide + .carousel-slide figure  {
  img {
    transform: scale(1.7);
    filter: grayscale(0);
  }
  @media only screen and (max-width: 1540px) {
    transform: scale(0.8);
  }
  & + .item__description {
    visibility: visible;
    text-align: center;
    align-items: center;
    margin-bottom: 1em;
    font-family: 'Comfortaa', cursive;
    font-size: 1em;
    font-weight: 400;
    @media only screen and (max-width: 1540px) {
      font-size: 0.8em;
    }
    .item__title {
      font-size: 1.2em;
    }
  }
}

h1 {
  text-align: center;
  font-family: 'Comfortaa', cursive;
  background-color: rgba(255, 255, 255, 0.70);
}

h1.no-background {
  font-size: 3rem;
  font-weight: 400;
  color: #b9b9b9;
  position: relative;
  padding: 0.5em 0;
  overflow: hidden;

  span {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    position: relative;
    padding: 0 20px;

    &:before, &:after {
      content: '';
      display: block;
      width: 1000px;
      position: absolute;
      top: 0.73em;
      border-top: 2.5px solid #b9b9b9;
    }

    &:before { right: 100%; }
    &:after { left: 100%; }
  }
}
</style>
